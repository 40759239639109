import React, {useState, useEffect} from 'react'
import List from './List'
import Alert from './Alert'

const getLocalStorage=()=>{
  let list = localStorage.getItem(('list'));
  if(list){
    return JSON.parse(localStorage.getItem('list'))
  }
  else{
    return []
  }
}
function App() {
  const [name, setName] = useState('');
  const [list, setList] = useState(getLocalStorage);
  const [isEditing, setIsEditing] = useState(false);
  const [editID, setEditID] = useState(null);
  const [alert, setAlert] = useState({show: false, msg: '', type: ''})

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name) {
      // display alert
      showAlert(true, 'bitte etwas eingeben', 'danger')

    } else if (name && isEditing) {
      setList(list.map((item)=>{
        if(item.id === editID){
          return {...item, title: name}
        }
        return item
      }))
      setName('');
      setEditID(null);
      setIsEditing(false);
      showAlert(true,'gespeichert', 'success')

    } else {
      showAlert(true,`${name} hinzugefügt`, 'success')
      const newItem = {id: new Date().getTime().toString(), title: name}
      setList([...list, newItem])
      setName('')
    }
  }

  const showAlert = (show=false, msg='', type='')=>{
    setAlert({show, msg, type})
  }

  const clearList =()=>{
    showAlert(true, 'Liste geleert', 'danger')
    setList([])
  }

  const removeItem =(id)=>{
    showAlert(true, `gelöscht`,'danger')
    setList(list.filter((item)=> item.id !== id))
  }

  const editItem = (id)=>{
    const specificItem = list.find((item)=> item.id === id);
    setIsEditing(true);
    setEditID(id);
    setName(specificItem.title)
  }

  useEffect(()=>{
    localStorage.setItem('list', JSON.stringify(list))
  },[list])
  return (
      <section className={'section-center'}>
        <form className={'grocery-form'} onSubmit={handleSubmit}>
          {alert.show && <Alert {...alert} removeAlert={showAlert} list/>}
          <h3>einkaufsliste</h3>
          <div className={'form-control'}>
            <input
                type={'text'}
                className={'grocery'}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <button
                type={'submit'}
                className={'submit-btn'}
            >
              {isEditing ? 'speichern' : 'hinzufügen'}

            </button>
          </div>
        </form>
        {list.length > 0 && (
            <div className={'grocery-container'}>
              <List items={list} removeItem={removeItem} editItem={editItem}/>
              {list.length > 12 && (
                  <button className={'clear-btn'} onClick={clearList}>
                    liste leeren
                  </button>
              )}

            </div>
        )}
      </section>
  )
}

export default App

